<template>
  <layout-view navTitle="Фильтры">
    <template v-slot:nav>
      <portal to="back">
        <v-btn icon @click="$router.push('../')">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </portal>
      <div class="px-4">
        <date-interval name="Дата" :valueFrom.sync="dateFrom" :valueTo.sync="dateTo" valueFromMinus="month:1" />

        <user-autocomplete-multiple name="Клиенты" :select.sync="usersClientSelected"/>

        <user-autocomplete-multiple name="Специалисты" _type="spec" :select.sync="usersSpecSelected"/>

        <user-autocomplete-multiple name="Направляющие" :select.sync="leadUsersSelected"/>

        <com-branch-autocomplete-multiple :select.sync="comBranchsSelected" globalState/>
   
        <v-autocomplete
            v-model="visitStatusSelected"
            :items="visitStatuses"
            :search-input.sync="visitStatusText"
            label="Статус"
            outlined
            dense
            hide-details="auto"
            multiple
            class="mb-4"
            item-text="name"
            item-value="id"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Не найдено</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:item="{item}">
            <v-list-item-title>
              <div class="py-2">
                <v-avatar :color="item.color" size="12" class="mr-2"/>{{item.name}}
              </div>
            </v-list-item-title>
          </template>
          <template v-slot:selection="{item}">
            <v-chip class="full-width mt-2" small style="width:95%">
              <v-row no-gutters>
                <v-col cols="auto"><v-avatar :color="item.color" class="circleIcon mr-2"/></v-col>
                <v-col class="text-overflow">{{item.name}}</v-col>
                <v-col cols="auto"><v-icon small @click.stop="visitStatusSelected=visitStatusSelected.filter((v)=>v!==item.id)">mdi-close-circle</v-icon></v-col>
              </v-row>
            </v-chip>
          </template>
        </v-autocomplete>

      </div>
    </template>
    <template v-slot:content="{height}">
      <n-table-view
        ref="table"
        name="Список"
        :genRequestBuilder="genRequestBuilder"
        :height="height"
        :headers="[
            { text: 'Статус', value:'status', sortable: true},
            { text: 'ID', value:'id', sortable: true},
            { text: 'Номер карты', value:'user.userProfile.medCardNumber', sortable: true},
            { text: 'Кто', value:'user.lastName', sortable: true},
            { text: 'Телефон', value:'user.phone', sortable: true},
            { text: 'К кому', value:'userSpec.user.lastName', sortable: true},
            { text: 'Специальность', value:'userSpec.spec.name', sortable: true},
            { text: 'Направляющий', value:'leadUser.lastName', sortable: true},
            { text: 'Кабинет', value:'comPlace.comRoom.sid', sortable: true},
            { text: 'Дата и время', value:'begin', sortable: true},
          ]"
      >
    
      <template v-slot:item="{ item }">
          <tr v-if="!!item.user" @click="visitId=item.id;">
            <td class="text-no-wrap">
              <visit-status :status="$tools.findById(visitStatuses, item.status)" :isPaidFull="item.paySummary.isPaidFull" :isSecond="item.isSecond" small/>
            </td>
            <td>{{ item.user.id }}</td>
            <td v-if="item.user.userProfile.medCardNumber">{{item.user.userProfile.medCardNumber}}</td>
            <td v-else>{{item.user.id}}</td>
            <td>{{$tools.userFullName(item.user)}}
              <v-btn
                    text
                    icon 
                    x-small
                    @click.stop="$tools.copyToClipboard($tools.userFullName(item.user));$store.commit('alertSuccess', 'ФИО скопировано');"
                  >
                 <v-icon small>mdi-content-copy</v-icon> 
              </v-btn>
              <v-btn 
                  @click.stop
                  x-small
                  icon depressed :href="'/panel/user/client/'+item.user.id+'/'" target="_blank">
                <v-icon small>mdi-open-in-new</v-icon>
              </v-btn>
            </td>
            <td class="text-no-wrap">
                <call-phone :phone="item.user.phone" />
                <v-btn
                      text
                      icon
                      x-small
                      @click.stop="$tools.copyToClipboard(item.user.phone);$store.commit('alertSuccess', 'Телефон скопирован');"
                    >
                  <v-icon small>mdi-content-copy</v-icon> 
                </v-btn>
            </td>
            <td class="text-no-wrap">
              <v-avatar class="user" size="24">
                <img :src="item.userSpec.user.imageUrl" v-if="item.userSpec.user.imageUrl" alt="">
              </v-avatar>
              {{$tools.userFullName(item.userSpec.user)}}
              <v-btn
                    text
                    icon 
                    x-small
                    @click.stop="$tools.copyToClipboard($tools.userFullName(item.userSpec.user));$store.commit('alertSuccess', 'ФИО скопировано');"
                  >
                 <v-icon small>mdi-content-copy</v-icon> 
              </v-btn>
              <v-btn 
                  @click.stop
                  x-small
                  icon depressed :href="'/panel/user/spec/'+item.userSpec.user.id+'/'" target="_blank">
                <v-icon small>mdi-open-in-new</v-icon>
              </v-btn>  
            </td>
            <td class="text-no-wrap">
              {{ item.userSpec.spec.name }}
            </td>
            <td class="text-no-wrap" @click.stop>
              <visit-lead-user :visitId="item.id" :user="item.leadUser" @delete="$refs.table.load()" @created="$refs.table.load()" small/>
            </td>
            <td class="text-no-wrap">
              {{ item.comPlace.comRoom.sid }}
            </td>
            <td class="text-no-wrap">{{$tools.dateTimeFormat(item.begin, 'DD.MM.YYYY')}} в {{$tools.dateTimeFormat(item.begin, 'HH:mm')}}</td>
          </tr>
        </template>
      </n-table-view>
      
      <visit :visitId.sync="visitId"/>
    </template>
  </layout-view>
</template>

<script>
import Api from "@/Api";
import LayoutView from "@/components/LayoutView";
import NTableView from "@/componentsV2/base/NTableView.vue";
// import LabelView from "@/components/LabelView";
import moment from "moment";
import DateInterval from "@/componentsV2/base/DateInterval.vue";
import ComBranchAutocompleteMultiple from "@/componentsV2/custom/ComBranchAutocompleteMultiple.vue";
import UserAutocompleteMultiple from "@/componentsV2/custom/UserAutocompleteMultiple.vue";
import VisitStatus from "@/components/VisitStatus";
import CallPhone from "@/components/CallPhone";
import Visit from "@/views/Visit/Visit";
import VisitLeadUser from "@/views/Visit/VisitLeadUser";

import {
  Entity_Visit, 
  Entity_User, 
  Entity_UserSpec, 
  Entity_UserProfile, 
  Entity_Spec,   
  Entity_ComPlace,
  Entity_ComRoom,
  Entity_ComFloor,
  Entity_ComBuilding,
  Entity_ComBranch} from "../../../EntityStoreCacheService";



export default {
  components: {
    NTableView,
    LayoutView,
    DateInterval,
    UserAutocompleteMultiple,
    ComBranchAutocompleteMultiple,
    VisitStatus,
    CallPhone,
    Visit,
    VisitLeadUser
  },

  data:()=>({
    dateFrom:null,
    dateTo:null,
    comBranchsSelected: [],

    visitStatusSelected:[],
    visitStatuses:Api.model.Visit.statuses,
    visitStatusText:null,

    visitId:null,

    usersClientSelected:[],
    usersSpecSelected:[],
    leadUsersSelected: [],
  }),
  watch:{
    dateFrom(){
      this.$refs.table.load();
    },
    dateTo(){
      this.$refs.table.load();
    },
    comBranchsSelected() {
      this.$refs.table.load();
    },
    visitStatusSelected() {
      this.$refs.table.load();
    },
    usersClientSelected() {
      this.$refs.table.load();
    },
    usersSpecSelected() {
      this.$refs.table.load();
    },
    leadUsersSelected(){
      this.$refs.table.load();
    }
  },
  methods:{
    genRequestBuilder(page, onPage, sortFields){
      let rb = new Entity_Visit()
        .selects(Entity_Visit.id)
        .selects(Entity_Visit.begin)
        .selects(Entity_Visit.comment)
        .selects(Entity_Visit.created)
        .selects(Entity_Visit.end)
        .selects(Entity_Visit.isSecond)
        .selects(Entity_Visit.leadUserComment)
        .selects(Entity_Visit.mark)
        .selects(Entity_Visit.status)

       
        //user
        .selects([Entity_Visit.user, Entity_User.id].join('.'))
        .selects([Entity_Visit.user, Entity_User.firstName].join('.'))
        .selects([Entity_Visit.user, Entity_User.lastName].join('.'))
        .selects([Entity_Visit.user, Entity_User.secondName].join('.'))
        .selects([Entity_Visit.user, Entity_User.phone].join('.'))
        .selects([Entity_Visit.user, Entity_User.userProfile, Entity_UserProfile.medCardNumber].join('.'))
  

        //userSpec
        .selects([Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.id].join('.'))
        .selects([Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.firstName].join('.'))
        .selects([Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.lastName].join('.'))
        .selects([Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.secondName].join('.'))
        .selects([Entity_Visit.userSpec, Entity_UserSpec.spec, Entity_Spec.name].join('.'))
        .selects([Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.imageUrl].join('.'))

        //leadUser
        .selects([Entity_Visit.leadUser, Entity_User.id].join('.'))
        .selects([Entity_Visit.leadUser, Entity_User.firstName].join('.'))
        .selects([Entity_Visit.leadUser, Entity_User.lastName].join('.'))
        .selects([Entity_Visit.leadUser, Entity_User.secondName].join('.'))
        .selects([Entity_Visit.leadUser, Entity_User.phone].join('.'))
        .selects([Entity_Visit.leadUser, Entity_User.userProfile, Entity_UserProfile.gender].join('.'))

        //comPlace
        .selects([Entity_Visit.comPlace, Entity_ComPlace.comRoom, Entity_ComRoom.sid].join('.'))
       
        //virtual
        .virtual(Entity_Visit.virtual_paySummary);

        rb.page(page).onPage(onPage)

        //filters

        //by combranch
        if(this.comBranchsSelected.length>0){          
          rb.filterAnd([Entity_Visit.entity, Entity_Visit.comPlace, Entity_ComPlace.comRoom, Entity_ComRoom.comFloor, Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch, Entity_ComBranch.id].join('.')+' in (?)',this.queryArray(this.comBranchsSelected,'id'));
        }

        //by date
        if(this.dateFrom) {  
          rb.filterAnd([Entity_Visit.entity, Entity_Visit.begin].join('.')+'>=?',moment(this.dateFrom, 'DD-MM-YYYY').format('YYYY-MM-DD 00:00:00'));
        } 

        if(this.dateTo) {  
          rb.filterAnd([Entity_Visit.entity, Entity_Visit.begin].join('.')+'<=?',moment(this.dateTo, 'DD-MM-YYYY').format('YYYY-MM-DD 23:59:59'));
        }

         //by user client
        if (this.usersClientSelected.length>0) {
          rb.filterAnd([Entity_Visit.entity, Entity_User.entity, Entity_User.id].join('.')+' in (?)', this.queryArray(this.usersClientSelected))
        }

        //by userSpec
        if (this.usersSpecSelected.length>0) {
          rb.filterAnd([Entity_Visit.entity, Entity_Visit.userSpec, Entity_User.id].join('.')+' in (?)',  this.queryArray(this.usersSpecSelected))
        }

        //by leadUsers
        if (this.leadUsersSelected.length>0) {
          rb.filterAnd([Entity_Visit.entity, Entity_Visit.leadUser, Entity_User.id].join('.')+' in (?)', this.queryArray(this.leadUsersSelected))
        }

        //by status
        if (this.visitStatusSelected.length>0) {
          rb.filterAnd([Entity_Visit.entity, Entity_Visit.status].join('.')+' in (?)', this.visitStatusSelected)
        }
    
        sortFields.forEach(v=>{
          rb.order(v.field, v.isAsc)
        })

        return rb;
    },
    queryArray(items,key='id') {
      let arr = [];
      items.forEach(s=>{
        arr.push(s[key]);
      });
      return arr;
    }
  }
}
</script>